import { useLingui } from '@lingui/react';
import { atom, useAtom, useSetAtom } from 'jotai';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  ErrorTypes,
  parseBsError,
} from 'src/components/modules/bet-slip-drawer/components/shared/error-message';
import { useOddsFormat } from 'src/hooks/use-odds-format';
import {
  betSlipsAtom,
  updateOneBetSlipAtom,
  xorBetslipsAtom,
} from 'src/store/bet-slip';
import { compareOutcome, isOddin } from 'src/utils/helpers/fixture';
import { generateAlias } from 'src/utils/helpers/ticket';
import { BaseEvent } from 'src/utils/types/event';
import { BaseOutcome } from 'src/utils/types/outcome';
import { BetSlipItemInterface, BetSource } from 'src/utils/types/sportsbook';

const addOutcomeToBsAtom = atom(
  (get) => get(betSlipsAtom),
  async (
    get,
    set,
    payload: {
      outcome?: BaseOutcome | null;
      event?: BaseEvent;
    },
  ) => {
    const { outcome, event } = payload;
    if (!event || !outcome) return;

    const source = isOddin(outcome?.eventId ?? event?.sportId)
      ? BetSource.ODDIN
      : BetSource.BET_RADAR;
    const addOrRemoveBetSlip: BetSlipItemInterface = {
      ...outcome,
      _rudderstack: {
        sportId: event.sportId,
        tournamentId: event.tournamentId,
      },
      source,
      alias: generateAlias({
        _id: outcome._id,
        eventId: outcome.eventId,
        source,
      }),
      eventName:
        event?.eventName ?? `${event.competitor1} - ${event.competitor2}`,
    };

    await set(xorBetslipsAtom, [addOrRemoveBetSlip]);
  },
);

export const useOutcome = (outcome?: BaseOutcome | null, event?: BaseEvent) => {
  const [betslips, setBetSlips] = useAtom(addOutcomeToBsAtom);
  const { enqueueSnackbar } = useSnackbar();
  const updateBs = useSetAtom(updateOneBetSlipAtom);

  const pos = useMemo(
    () =>
      outcome
        ? _.findIndex(betslips, (bs) => {
            return compareOutcome(bs, outcome);
          })
        : -1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      outcome?.eventId,
      outcome?.marketId,
      outcome?.marketSpecifiers,
      outcome?.outcomeId,
      outcome?.producerId,
      outcome?._id,
      betslips.length,
    ],
  );
  const odds = useOddsFormat(outcome?.currentOdd ?? '0');
  const { i18n } = useLingui();

  useUpdateEffect(() => {
    if (pos === -1) return;
    updateBs(pos, { ...outcome });
  }, [outcome?.currentOdd, pos]);

  const isSelected = pos !== -1;

  const addToCart = () => {
    setBetSlips({ event, outcome }).catch((err: any) => {
      if (err.code === ErrorTypes.SelfExclude) {
        enqueueSnackbar(parseBsError({ code: ErrorTypes.SelfExclude, i18n }), {
          variant: 'error',
          className: 'ubet-err',
        });
      }
    });
  };

  return { isSelected, addToCart, odds };
};
